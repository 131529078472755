/* eslint-disable @typescript-eslint/no-explicit-any */

import WithAuthenticationRequired, { WithAuthenticationRequiredOptions } from "../WithAuthenticationRequired/WithAuthenticationRequired";

import React from "react";
import { Route } from "react-router-dom";

interface PrivateRouteProps extends WithAuthenticationRequiredOptions {
    component: React.ComponentType<any>;
    [key: string]: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
    const { component, allowIfHasRole, allowIfHasAllRoles, allowIfHasAnyRole, ...rest } = props;
    return <Route component={WithAuthenticationRequired(component, { allowIfHasRole, allowIfHasAllRoles, allowIfHasAnyRole })} {...rest} />;
};

export default PrivateRoute;
