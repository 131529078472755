import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

const AnalyticsDashboard = lazy(() => import("./components/AnalyticsDashboard/AnalyticsDashboard"));
const AuthorizationCallback = lazy(() => import("./components/AuthorizationCallback/AuthorizationCallback"));
const ClientPage = lazy(() => import("./components/ClientPage/ClientPage"));
const ClientSearch = lazy(() => import("./components/ClientSearch/ClientSearch"));
const CodeConditions = lazy(() => import("./components/UWCode/CodeConditions"));
const Compare = lazy(() => import("./components/UWCompare/Compare"));
const ComparePage = lazy(() => import("./components/UWSCompare/ComparePage"));
const CodePage = lazy(() => import("./components/UWSCode/CodePage"));
const CdrQueue = lazy(() => import("./components/CdrQueue/CdrQueue"));
const CdrRequest = lazy(() => import("./components/CdrRequest/CdrRequest"));
const DataCleanupReport = lazy(() => import("./components/DataCleanupReport/DataCleanupReport"));
const SharedDashboard = lazy(() => import("./components/SharedDashboard/SharedDashboard"));
const IDIResultsPage = lazy(() => import("./components/IDIResultModal/IDIResultPage"));
const ImageViewer = lazy(() => import("./components/ImageViewer/ImageViewer"));
const Icd10Management = lazy(() => import("./components/Icd10Management/Icd10Management"));
const InsuredSearch = lazy(() => import("./components/InsuredSearch/InsuredSearch"));
const LECalculator = lazy(() => import("./components/LECalculator/LECalculator"));
const LEWorksheet = lazy(() => import("./components/LEWorksheet/LEWorksheet"));
const LRMReport = lazy(() => import("./components/LRM/LRMReport"));
const MassCalc = lazy(() => import("./components/MassCalc/MassCalc"));
const MedRecHandlingQueue = lazy(() => import("./components/MedRecHandlingQueue/MedRecHandlingQueue"));
const MergeClients = lazy(() => import("./components/MergeClients/MergeClients"));
const MergeInsured = lazy(() => import("./components/MergeInsured/MergeInsured"));
const MessageInbox = lazy(() => import("./components/MessageInbox/MessageInbox"));
const MyCaseload = lazy(() => import("./components/MyCaseload/MyCaseload"));
const OnlineSubmissions = lazy(() => import("./components/OnlineSubmissions"));
const RequestSearch = lazy(() => import("./components/RequestSearch/RequestSearch"));
const ReviewCodedPages = lazy(() => import("./components/ReviewCodedPages/ReviewCodedPages"));
const SubmitDetails = lazy(() => import("./components/SubmitQueue/SubmitDetails/SubmitDetails"));
const SubmitQueue = lazy(() => import("./components/SubmitQueue/SubmitQueue"));
const ReportGenerator = lazy(() => import("./components/ReportGenerator/ReportGenerator"));
const UserDetails = lazy(() => import("./components/User/User"));
const UserSearch = lazy(() => import("./components/UserSearch/UserSearch"));
const UWSManagementQueue = lazy(() => import("./components/UWSManagementQueue"));
const UWQueue = lazy(() => import("./components/UWQueue"));

const AppRoutes: React.FC = () => {
    return (
        <Suspense fallback={<div>loading...</div>}>
            <Switch>
                <Route path="/callback" component={AuthorizationCallback} />
                <PrivateRoute path="/AnalyticsDashboard" component={AnalyticsDashboard} />
                <PrivateRoute path="/Client/:ClientID" component={ClientPage} />
                <PrivateRoute path="/ClientSearch" component={ClientSearch} />
                <PrivateRoute path="/uw-code/:RequestID" component={CodeConditions} />
                <PrivateRoute path="/uw-compare/:RequestID?" component={Compare} />
                <PrivateRoute path="/ComparePage/:provider/:ID/:FilePageID?" component={ComparePage} />
                <PrivateRoute path="/CodePage/:provider/:ID/:FilePageID?" component={CodePage} />
                <PrivateRoute path="/CdrQueue" component={CdrQueue} />
                <PrivateRoute path="/CdrRequest/:CdrRequestID" component={CdrRequest} />
                <PrivateRoute path="/DataCleanupReport" component={DataCleanupReport} />
                <PrivateRoute path="/SharedDashboard" component={SharedDashboard} />
                <PrivateRoute path="/IDIResults/:submitID" component={IDIResultsPage} />
                <PrivateRoute path="/ImageViewer/:provider/:uuid" component={ImageViewer} />
                <PrivateRoute path="/Icd10Management" component={Icd10Management} />
                <PrivateRoute path="/InsuredSearch" component={InsuredSearch} />
                <PrivateRoute path="/le-calculator/:RequestID?" component={LECalculator} />
                <PrivateRoute path="/LEWorksheet/:ID" component={LEWorksheet} />
                <PrivateRoute path="/LRMReport" component={LRMReport} />
                <PrivateRoute path="/MassCalc" component={MassCalc} />
                <PrivateRoute path="/MedRecHandlingQueue" component={MedRecHandlingQueue} />
                <PrivateRoute path="/MergeClients" component={MergeClients} />
                <PrivateRoute path="/MergeInsured/:ins1?/:ins2?" component={MergeInsured} />
                <PrivateRoute path="/MessageInbox" component={MessageInbox} />
                <PrivateRoute path="/MyCaseload" component={MyCaseload} />
                <PrivateRoute path="/OnlineSubmissions/:SubmitID?" component={OnlineSubmissions} />
                <PrivateRoute path="/RequestSearch" component={RequestSearch} />
                <PrivateRoute path="/ReviewCodedPages/:provider/:ID" component={ReviewCodedPages} />
                <PrivateRoute path="/SubmitDetails/:provider/:ID" component={SubmitDetails} />
                <PrivateRoute path="/SubmitQueue/:source?" component={SubmitQueue} />
                <PrivateRoute path="/ReportGenerator/:report?" component={ReportGenerator} />
                <PrivateRoute path="/UWQueue" component={UWQueue} />
                <PrivateRoute path="/UWSManagementQueue" component={UWSManagementQueue} />
                <PrivateRoute path="/User" exact component={UserSearch} />
                <PrivateRoute path="/User/:authID" component={UserDetails} />
                <Redirect to="/RequestSearch" />
            </Switch>
        </Suspense>
    );
};

export default AppRoutes;
